<template>
    <div class="footer footer-main py-5 bg-primary">
        <div class="text-light text-center container-xl d-flex flex-column flex-lg-row justify-content-around align-items-center">
            <p class="mb-0">Ha kérdésed van, írj nekünk a <a class="text-decoration-underline text-white" :href="'mailto:'+$api_conf.emailAddress">{{ $api_conf.emailAddress }}</a> e-mail címre!<br>Amennyiben nem kaptad meg a válaszüzenetet, kérjük, ellenőrizd a spam mappában is!</p>
        </div>        
        <div>            
           <!-- <CookieBannerComp 
            class="w-100  d-flex align-items-center justify-content-center justify-content-md-start mb-0"/>   -->         
        </div>
    </div>
</template>


<script>
//import CookieBannerComp from '@/plugins/cookiebanner/CookieBannerComp.vue'
// import AgeGateComp from '@/components/AgeGateComp.vue'

export default {
    components: {
        //CookieBannerComp,
        // AgeGateComp     
    }
}
</script>